import Link from 'next/link';
import Loader from '@/components/Loader';
import styles from './index.module.scss';
import { useTranslations } from 'next-intl';

type ButtonProps = {
  action: () => void;
  title: string;
  showLoading?: boolean;
  disabled?: boolean;
  variant?: string;
  style?: Record<string, string>;
  translate?: boolean;
};

type LinkProps = {
  link: string;
  title: string;
  variant?: string;
};

const Button: React.FC<ButtonProps> = ({
  action,
  title,
  showLoading,
  disabled,
  variant,
  style,
  translate = true,
}) => {
  const t = useTranslations();
  return (
    <button
      className={`${styles.primaryButton} ${variant ? styles[variant] : ''}`}
      style={style}
      onClick={action}
      disabled={disabled}
    >
      {!showLoading && <p>{translate ? t(title) : title}</p>}
      {showLoading && <Loader showText={false} imageSpec={27} />}
    </button>
  );
};

export const LinkButton: React.FC<LinkProps> = ({ link, title, variant }) => {
  return (
    <Link className={`${variant ? styles[variant] : ''}`} href={link}>
      {title}
    </Link>
  );
};

export default Button;
