import { NotificationData } from "@/components/Notification";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface INotifData {
    notificationData: NotificationData[],
    isNextpageAvailable: boolean,
    pageCursor: string | null,
    unreadCount: number,
}

const initialState: INotifData = {
    notificationData: [],
    isNextpageAvailable: false,
    pageCursor: null,
    unreadCount: 0,
};

export const notifSlice = createSlice({
    name: "charData",
    initialState,
    reducers: {
        setNotifData: (state, action: PayloadAction<NotificationData[]>) => {
            state.notificationData = action.payload;
        },
        addNotifData: (state, action: PayloadAction<NotificationData[]>) => {
            state.notificationData = [...action.payload, ...state.notificationData];
        },
        removeNotifData: (state, action: PayloadAction<string>) => {
            state.notificationData = state.notificationData.filter((notif) => notif.id !== action.payload);
        },
        updateNotifCursor: (state, action: PayloadAction<{ isNextpageAvailable: boolean, pageCursor: string | null }>) => {
            const { isNextpageAvailable, pageCursor } = action.payload;
            state.isNextpageAvailable = isNextpageAvailable;
            state.pageCursor = pageCursor
        },
        setUnreadCount: (state, action: PayloadAction<number>) => {
            state.unreadCount = action.payload;
        },
        decrementUnreadCount: (state) => {
            state.unreadCount -= 1;
            if (state.unreadCount < 0)
                state.unreadCount = 0;
        },
        incrementUnreadCount: (state) => {
            state.unreadCount += 1;
        },
    },
});

export const { setNotifData, addNotifData, removeNotifData, updateNotifCursor, setUnreadCount,
    decrementUnreadCount, incrementUnreadCount } = notifSlice.actions;
export const notifReducer = notifSlice.reducer;