import { CharactersData } from "@/app/dashboard/types";

type CharChatHistoryData = {
    chatSessionId: string;
    characterId: string;
    user_id: string;
    id?: string;
    characterName: string;
    description?: string;
    characterImageUrl: string;
    createdAt?: {
        _seconds: string;
    };
    updatedAt?: {
        _seconds: number;
    };
    lastMessage?: string;
};

export const modifyString = (text: string) => {
    const modifiedText = text.replace(/\*([\w\s$&+,:!'"`.;=?@#]+)\*/g, '<span>$1</span>');
    return modifiedText;
};

export const filterRemainingFeeds = (chars: CharactersData[], chatHistory: CharChatHistoryData[], limit?: number) => {
    const charIds = chatHistory.map(chat => chat.characterId);
    const uniqueIds = [];
    const remainingChars = chars.filter(char => charIds.indexOf(char.id) === -1);
    for (let i = 0; i < remainingChars.length; i++) {
        const charId = remainingChars[i].id;
        if (uniqueIds.indexOf(charId) === -1) {
            uniqueIds.push(charId);
        }
        if (limit && uniqueIds.length === limit) {
            break;
        }
    }
    return uniqueIds;
};

export const formatViewCount = (views: number): string => {
    if (views < 0 || !views) return '0';

    // Handle special case for numbers less than 1000
    if (views < 1000) {
        return views.toString();
    }

    // Billions
    if (views >= 1000000000) {
        const billions = views / 1000000000;
        // If it's exactly X billion, don't show decimal
        if (billions === Math.floor(billions)) {
            return `${Math.floor(billions)}B`;
        }
        return `${billions.toFixed(1)}B`;
    }

    // Millions
    if (views >= 1000000) {
        const millions = views / 1000000;
        if (millions === Math.floor(millions)) {
            return `${Math.floor(millions)}M`;
        }
        return `${millions.toFixed(1)}M`;
    }

    // Thousands
    if (views >= 1000) {
        const thousands = views / 1000;
        if (thousands === Math.floor(thousands)) {
            return `${Math.floor(thousands)}K`;
        }
        return `${thousands.toFixed(1)}K`;
    }
    return views.toString();
};

export const isSSR = () => typeof window === 'undefined';

export const getUtmParams = () => ({
    utmSource: window.sessionStorage?.getItem('utmSource') || 'NA',
    utmMedium: window.sessionStorage?.getItem('utmMedium') || 'NA',
    utmCampaign: window.sessionStorage?.getItem('utmCampaign') || 'NA',
});

export const isValidFileType = (fileExtension: string): boolean => {
    if (fileExtension === "jpg" || fileExtension == "jpeg" || fileExtension === "png" || fileExtension === "heic" || fileExtension === "heif" || fileExtension === "webp" || fileExtension === "avif") {
        return true;
    }
    return false;
};

export const removeBetweenAsterisks = (str: string) => {
    const pattern = /\*[\s\S]*?\*/g;
    return str.replace(pattern, '');
};

export const removeHTMLTags = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const textContent = doc.body.textContent || "";
    return textContent.trim();
}
