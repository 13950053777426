'use client';

import ErrorContainer from '@/components/ErrorScreen/ErrorContainer';
import useApi from '@/hooks/useApi';
import {
  setDeviceType,
  setError,
  setErrorToast,
  setOrientation,
} from '@/lib/features/deviceSlice';
import {
  setRedirectUrl,
  setUserData,
  setUserDetailsScreen,
  updateInstallPromptStatus,
  updateNSFWValue,
  updateUserData,
} from '@/lib/features/userSlice';
import { getReleaseStage } from '@/utils/host';
import Bugsnag, { BreadcrumbType } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import { RootState } from '@/lib/store';
import { fetchAccessToken, linkToken, login, reportEvent } from '@/utils/api';
import { getFeDeviceDetails, getOS, isDesktop } from '@/utils/device';
import { v4 as uuidv4 } from 'uuid';
import { useTranslations } from 'next-intl';
import {
  useParams,
  usePathname,
  useRouter,
  useSearchParams,
} from 'next/navigation';
import { useEffect, useRef, useState } from 'react';
import { getAuth, signInAnonymously } from 'firebase/auth';
import * as firebase from 'firebase/app';
import { getFirebaseConfig } from '@/utils/firebase';
import 'groupby-polyfill/lib/polyfill.js';
import { getUtmParams, isSSR } from '@/utils';
import React from 'react';
import GoogleAnalytics from '../metrics/GoogleAnalytics';

type IResponse = {
  body: {
    userId: string;
    nickName: string;
    avatarUrl: string;
    gender: string;
    ageRange: string;
  };
  status: string;
};

const UserLogin: React.FC = () => {
  const dispatch = useAppDispatch();
  const searchParams = useSearchParams();
  const [isExistingUser, setExistingUser] = useState(false);
  const [tokenGenerated, setTokenGenerated] = useState(false);
  const isSignedIn = useRef<boolean>(false);
  const { charId, feedId } = useParams();
  const characterId = searchParams.get('charId');
  const chatId = searchParams.get('chatId');
  const pathname = usePathname();
  const {
    id: userDataId,
    accessToken,
    isNewUser,
    age,
    gender,
  } = useAppSelector((state: RootState) => state.user.userData);
  const { selectedSlide } = useAppSelector((state: RootState) => state.user);
  const selectedSlideRef = useRef(selectedSlide);
  const t = useTranslations();
  const hostname =
    typeof window !== 'undefined' && window.location.hostname
      ? window.location.hostname
      : '';
  const { responseData, isLoading, error, fetchData } = useApi<IResponse>(
    'GET',
    `/ai/api/char/v1/userInfo`
  );
  const {
    responseData: userResponseData,
    error: userError,
    fetchData: userFetchData,
  } = useApi<IResponse>('POST', `/ai/api/char/v1/users`, {
    ageRange: 'NA',
    gender: 'NA',
  });
  const { showErrorScreen } = useAppSelector(
    (state: RootState) => state.device.deviceData
  );
  const router = useRouter();
  const handleOrientation = () => {
    let orientation;
    if (screen.orientation) {
      orientation = screen.orientation.type;
      if (orientation.includes('landscape')) {
        orientation = 'landscape';
      }
    } else {
      orientation = window.orientation;
      if (orientation == 90 || orientation == -90) {
        orientation = 'landscape';
      }
    }
    if (orientation === 'landscape' && !isDesktop()) {
      document.body.classList.add('landscape');
      document.documentElement.classList.add('landscape');
      dispatch(setOrientation({ orientation: 'landscape' }));
    } else {
      document.body.classList.remove('landscape');
      document.documentElement.classList.remove('landscape');
      dispatch(setOrientation({ orientation: 'portrait' }));
    }
  };
  const getSource = (): string => {
    const location = window.location.pathname;
    let source = 'home';
    let sourceValues = ['feeds', 'explore', 'camera', 'chats', '', 'profile'];
    let desktopSourceValues = ['feeds', 'explore', 'chats', 'profile'];
    if (location.includes('/dashboard/characters/')) {
      source = 'characterDetails';
    } else if (location.includes('/dashboard/feeds/')) {
      source = 'feedDetails';
    } else {
      switch (location) {
        case '/sup-ai/dashboard/create': {
          source = 'create';
          break;
        }
        case '/sup-ai/dashboard': {
          const queryParams = window.location.search;
          if (queryParams.length) {
            source = 'chatScreen';
            break;
          }
          if (isDesktop()) {
            source = desktopSourceValues[selectedSlideRef.current];
          } else {
            source = sourceValues[selectedSlideRef.current];
          }
          break;
        }
      }
    }
    return source;
  };
  const trackUpTime = (
    startDate: number,
    source: string,
    isFirstUptime?: boolean
  ) => {
    const extraData: {
      elapsedTime: number;
      intervalDurationSecs: number;
      isNewUser?: boolean;
      source?: string;
      selectedSlide: number;
    } = {
      elapsedTime: (Date.now() - startDate) / 1000,
      intervalDurationSecs: 30,
      selectedSlide: selectedSlideRef.current,
    };
    if (isNewUser && isFirstUptime) {
      extraData.isNewUser = true;
    }
    extraData.source = isNewUser ? 'home' : source;
    reportEvent({
      eventName: 'feUptime',
      userId: userDataId as string,
      age,
      gender,
      extraData,
    });
  };
  useEffect(() => {
    selectedSlideRef.current = selectedSlide;
  }, [selectedSlide]);
  useEffect(() => {
    if (!isLoading && responseData && responseData.status === 'Success') {
      const { userId, avatarUrl, nickName, gender, ageRange } =
        responseData.body;
      dispatch(
        setUserData({
          id: userId,
          avatar: avatarUrl,
          name: nickName,
          gender,
          accessToken: accessToken,
          age: ageRange,
        })
      );
      const queryParams = window.location.search;
      if (queryParams.length) {
        if (pathname.indexOf('create') > -1) {
          router.replace(`/dashboard/create${queryParams}`);
        } else {
          router.replace(`/dashboard${queryParams}`);
        }
      } else if (charId || feedId) {
      } else {
        if (pathname.indexOf('create') > -1) {
          router.replace('/dashboard/create');
        } else {
          router.replace('/dashboard');
        }
      }
      dispatch(setUserDetailsScreen(false));
    } else if (error) {
      const { body } = error;
      if (body && body.message === t('userNotFound')) {
        const utmCampaign = sessionStorage.getItem('utmCampaign');
        if (utmCampaign === 'roblox-link') {
          dispatch(setUserDetailsScreen(false));
          userFetchData();
          return;
        }
        const userId = body.authUserId;
        dispatch(
          updateUserData({
            id: userId,
            isNewUser: true,
            userVerified: false,
            likedData: {},
          })
        );
        if ((characterId && chatId) || charId || feedId) {
          dispatch(setRedirectUrl(window.location.href));
        }
        router.push('/');
        dispatch(setUserDetailsScreen(true));
      } else {
        dispatch(setError({ showErrorScreen: true }));
      }
    }
  }, [responseData, isLoading, error]);
  useEffect(() => {
    if (userResponseData && userResponseData.status === 'Success') {
      const { userId, avatarUrl, nickName, gender, ageRange } =
        userResponseData.body;
      dispatch(
        setUserData({
          id: userId,
          avatar: avatarUrl,
          name: nickName,
          gender,
          isNewUser: true,
          age: ageRange,
          userVerified: true,
        })
      );
    } else if (userError) {
      const { body } = userError;
      if (body && body.message === t('userNotFound')) {
        dispatch(
          updateUserData({
            likedData: {},
          })
        );
        router.push('/');
        dispatch(setUserDetailsScreen(true));
      } else {
        dispatch(setError({ showErrorScreen: true }));
      }
    }
  }, [userResponseData, userError]);
  useEffect(() => {
    const bugsnagConfig = {
      releaseStage: getReleaseStage(),
      appType: isSSR() ? 'server' : 'browser',
      appVersion: '1.0.0',
      enabledBreadcrumbTypes: [
        'error',
        'navigation',
        'request',
        'user',
      ] as BreadcrumbType[],
      trackInlineScripts: false,
    };
    Bugsnag.start({
      ...bugsnagConfig,
      apiKey: '63b1dec23ba986b4da135bc99bdf9aec',
      plugins:
        typeof window === 'undefined' ? [] : [new BugsnagPluginReact(React)],
      onError: (event) => {
        event.groupingHash =
          event.originalError.message ||
          JSON.stringify(event.originalError) ||
          event.originalError.errorClass;
        if (typeof window !== 'undefined') {
          event.addMetadata('userData', {
            uaSessionId: window.sessionStorage.getItem('fe_uaSessionId'),
            uaId: window.localStorage.getItem('fe_uaId'),
            userId: userDataId,
          });
          event.addMetadata('clientInfo', {
            userAgent: navigator?.userAgent,
            visibilityState: document?.visibilityState,
            onLine: navigator?.onLine,
            utm: getUtmParams(),
            url: window?.location?.href,
            os: getFeDeviceDetails().feOsName,
            device: getFeDeviceDetails().feDeviceType,
          });
        }
      },
    });
    BugsnagPerformance.start({ apiKey: '63b1dec23ba986b4da135bc99bdf9aec' });
    const firebaseConfig = getFirebaseConfig();
    firebase.initializeApp(firebaseConfig);
    let isNSFWEnabled = false;
    if (window.localStorage) {
      isNSFWEnabled = window.localStorage.getItem('isNSFWEnabled') === 'true';
    }
    if (isNSFWEnabled) {
      dispatch(updateNSFWValue(true));
    }
    const source = sessionStorage.getItem('utmSource');
    const campaign = sessionStorage.getItem('utmCampaign');
    const medium = sessionStorage.getItem('utmMedium');
    const utmCampaign = searchParams.get('utm_campaign') || campaign || 'NA';
    const utmSource = searchParams.get('utm_source') || source || 'NA';
    const utmMedium = searchParams.get('utm_medium') || medium || 'NA';
    sessionStorage.setItem('utmCampaign', utmCampaign);
    sessionStorage.setItem('utmSource', utmSource);
    sessionStorage.setItem('utmMedium', utmMedium);
    if (screen.orientation) {
      screen.orientation.addEventListener('change', handleOrientation);
    } else {
      window.addEventListener('orientationchange', handleOrientation);
    }
    handleOrientation();
    const getAccessToken = async () => {
      try {
        const auth = getAuth();
        auth.onAuthStateChanged(async () => {
          const { currentUser } = auth;
          if (currentUser) {
            const isUserSignedIn = currentUser.emailVerified;
            const userEmail = currentUser.email;
            const token = await currentUser.getIdToken();
            if (!isSignedIn.current) {
              if (userEmail && !isUserSignedIn) {
                dispatch(
                  updateUserData({
                    showVerificationScreen: true,
                    email: userEmail,
                    isSignedIn: true,
                  })
                );
              } else if (isUserSignedIn) {
                login(token, currentUser.uid).then((res) => {
                  if (res === 'Fail') {
                    dispatch(setErrorToast({ showErrorToast: true }));
                  }
                });
                dispatch(
                  updateUserData({
                    showVerificationScreen: false,
                    email: userEmail || '',
                    isSignedIn: true,
                  })
                );
              }
              isSignedIn.current = true;
              setExistingUser(true);
              dispatch(updateUserData({ accessToken: token }));
            }
          } else {
            isSignedIn.current = true;
            signInAnonymously(auth).then(async (credentials) => {
              const { user } = credentials;
              const accessToken = await user.getIdToken();
              dispatch(updateUserData({ accessToken }));
            });
          }
        });
      } catch (e) {
        dispatch(setError({ showErrorScreen: true }));
      }
    };
    window.deferredPrompt = null;
    getAccessToken();
    const installPromptCount = localStorage.getItem('installPromptCount');
    if (getOS() === 'android' && parseInt(installPromptCount || '0') < 3) {
      window.addEventListener('beforeinstallprompt', (e) => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        window.deferredPrompt = e as BeforeInstallPromptEvent;
      });
      const timeoutForInstallPrompt =
        getReleaseStage() === 'Prod' ? 600000 : 20000;
      setTimeout(() => {
        if (window.deferredPrompt) {
          dispatch(updateInstallPromptStatus(true));
        }
      }, timeoutForInstallPrompt);
    }
    if ('serviceWorker' in navigator) {
      const subpath = '/sup-ai/';
      navigator.serviceWorker
        .register(`https://${window?.location?.host || ''}${subpath}sw.js`)
        .then(
          () => {
            console.log('Service worker registration succeeded');
          },
          () => {
            console.error('Service worker registration failed');
          }
        );
    } else {
      console.error('Service workers are not supported.');
    }
    dispatch(
      setDeviceType({
        device: getFeDeviceDetails().feDeviceType,
        os: getFeDeviceDetails().feOsName,
      })
    );
    return () => {
      if (screen.orientation) {
        screen.orientation.removeEventListener('change', handleOrientation);
      } else {
        window.removeEventListener('change', handleOrientation);
      }
    };
  }, []);
  useEffect(() => {
    if (!tokenGenerated) {
      if (accessToken && !isExistingUser) {
        const getBaseAccessToken = async () => {
          const baseAccessToken = await fetchAccessToken();
          return baseAccessToken;
        };
        getBaseAccessToken().then((res) => {
          if (res === 'Fail') {
            dispatch(setError({ showErrorScreen: true }));
          } else {
            setTokenGenerated(true);
            if (
              res.code === 'EXISTING_GUEST_USER' ||
              res.code === 'EXISTING_LOGGED_IN_USER'
            ) {
              linkToken(
                accessToken,
                res.accessToken,
                userDataId as string
              ).then((linkTokenres) => {
                if (linkTokenres === 'Success') {
                  fetchData();
                } else {
                  dispatch(setError({ showErrorScreen: true }));
                }
              });
            } else {
              fetchData();
            }
          }
        });
      } else if (accessToken && isExistingUser) {
        setTokenGenerated(true);
        fetchData();
      }
    }
  }, [accessToken, isExistingUser]);
  useEffect(() => {
    let upTimeInterval: ReturnType<typeof setInterval>;
    if (userDataId) {
      let sessionStartDate: string | null | number =
        sessionStorage.getItem('startDate');
      if (sessionStartDate) {
        sessionStartDate = parseInt(sessionStartDate);
        sessionStorage.removeItem('startDate');
      }
      const startDate = (sessionStartDate as number) || Date.now();
      const uaId = localStorage.getItem('wsup_uaId');
      const uaSessionId = sessionStorage.getItem('wsup_uaSessionId');
      if (!uaId) {
        localStorage.setItem('wsup_uaId', `ua-${uuidv4()}`);
      }
      if (!uaSessionId) {
        sessionStorage.setItem('wsup_uaSessionId', `uasess-${uuidv4()}`);
      }
      const intervalTime = 30000;
      const source = getSource();
      trackUpTime(startDate, source, true);
      upTimeInterval = setInterval(() => {
        if (document.visibilityState === 'visible') {
          const source = getSource();
          trackUpTime(startDate, source);
        }
      }, intervalTime);
    }
    return () => clearInterval(upTimeInterval);
  }, [userDataId]);
  return (
    <>
      {hostname === 'wsup.ai' && <GoogleAnalytics />}
      {showErrorScreen && <ErrorContainer />}
    </>
  );
};

export default UserLogin;
