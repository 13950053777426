import Bugsnag, { NotifiableError } from "@bugsnag/js";
import { getFeDeviceDetails } from "./device";
import { getApiHost, getAuthHost } from "./host";
import { getUtmParams } from ".";
import { getAuth } from "firebase/auth";

type ITokenResponse = {
    access_token: string,
    success: boolean,
    code: string
};

type IPayloadStats = {
    eventName: string,
    userId: string,
    chatSessionId?: string,
    deviceType?: string,
    personaId?: string,
    extraData?: Record<string, string | number | boolean | string[]>,
    source?: string,
    age?: string,
    gender?: string,
    characterIds?: Array<string>
}

type IError = {
    status: string;
};

type IAnalyzePayload = {
    userId: string,
    chatSessionId: string,
    characterId: string,
    requestId: string,
    base64Image: string
};

export const reportBugsnagError = (e: NotifiableError, id?: string) => {
    Bugsnag.notify(e, (event) => {
        event.addMetadata('userData', {
            uaSessionId: window.sessionStorage.getItem('fe_uaSessionId'),
            uaId: window.localStorage.getItem('fe_uaId'),
            userId: id,
        });
        if (typeof window !== 'undefined') {
            event.addMetadata('clientInfo', {
                userAgent: navigator?.userAgent,
                visibilityState: document?.visibilityState,
                onLine: navigator?.onLine,
                utm: getUtmParams(),
                url: window?.location?.href,
                os: getFeDeviceDetails().feOsName,
                device: getFeDeviceDetails().feDeviceType,
            });
        }
    });
};

export const fetchChatdata = async (userId: string, characterId: string, forceNewSession?: boolean) => {
    const host = getApiHost();
    const uaId = window.localStorage.getItem('fe_uaId');
    const uaSessionId = window.sessionStorage.getItem('fe_uaSessionId');
    const wsupUaId = window.localStorage.getItem('wsup_uaId');
    const wsupUaSessionId = window.sessionStorage.getItem('wsup_uaSessionId');
    const feOsName = getFeDeviceDetails().feOsName;
    const feDeviceType = getFeDeviceDetails().feDeviceType;
    const utmCampaign = sessionStorage.getItem('utmCampaign');
    const utmSource = sessionStorage.getItem('utmSource');
    const utmMedium = sessionStorage.getItem('utmMedium');
    const auth = getAuth();
    const { currentUser } = auth;
    const accessToken = await currentUser?.getIdToken();
    try {
        const chatDetails = await fetch(`${host}/ai/api/char/v1/session`, {
            method: 'POST',
            headers: {
                client: 'nowgg',
                'Authorization': `WsupV1 ${accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId, uaId, uaSessionId, wsupUaId, wsupUaSessionId, feOsName, feDeviceType, characterId, forceNewSession, utmCampaign, utmSource, utmMedium })
        });
        const response = await chatDetails.json();
        return response;
    } catch (e) {
        if ((e as IError).status !== 'FailureUnauthorized') {
            reportBugsnagError(new Error(`Session API Fail: ${e}`) as NotifiableError, userId);
        }
        return { status: 'Fail' }
    }
};

export const fetchAccessToken = async () => {
    const host = getAuthHost();
    try {
        const accessToken = await fetch(`${host}/accounts/auth/v2/access-token?implicitGuestLogin=false`, {
            credentials: 'include'
        });
        const res: ITokenResponse = await accessToken.json();
        if (res.success) {
            return { accessToken: res.access_token, code: res.code };
        } else {
            if (res.code === 'AUTHORIZATION_EMPTY') {
                return { code: res.code, accessToken: '' }
            }
            reportBugsnagError(new Error(`Access Token API Fail: ${res.code}`) as NotifiableError)
            return 'Fail';
        }
    } catch (e) {
        if ((e as IError).status !== 'FailureUnauthorized') {
            reportBugsnagError(new Error(`Access Token API Fail: ${e}`) as NotifiableError);
        }
        return 'Fail';
    }
};

export const linkToken = async (firebaseToken: string, nowToken: string, userId: string) => {
    const host = getApiHost();
    try {
        const response = await fetch(`${host}/ai/api/auth/v1/linkToken`, {
            method: 'POST',
            headers: {
                client: 'nowgg',
                'Authorization': `WsupV1 ${firebaseToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ nowToken })
        });
        const res: { status: string; } = await response.json();
        if (res.status === 'Success') {
            return 'Success';
        } else {
            reportBugsnagError(new Error(`Link Token API Fail: ${res.status}`) as NotifiableError, userId);
            return 'Fail';
        }
    } catch (e) {
        if ((e as IError).status !== 'FailureUnauthorized') {
            reportBugsnagError(new Error(`Link Token API Fail: ${e}`) as NotifiableError, userId);
        }
        return 'Fail';
    }
};

export const login = async (firebaseToken: string | undefined, id: string) => {
    const host = getApiHost();
    try {
        const response = await fetch(`${host}/ai/api/auth/v1/login`, {
            method: 'POST',
            headers: {
                client: 'nowgg',
                'Authorization': `WsupV1 ${firebaseToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({})
        });
        const res: { status: string } = await response.json();
        if (res.status === 'Success') {
            return 'Success';
        } else {
            reportBugsnagError(new Error(`Login API Fail: ${res.status}`) as NotifiableError, id);
            return 'Fail';
        }
    } catch (e) {
        if ((e as IError).status !== 'FailureUnauthorized') {
            reportBugsnagError(new Error(`Login API Fail: ${e}`) as NotifiableError, id);
        }
        return 'Fail';
    }
};

export const reportEvent = async (payload: IPayloadStats, type = 'stats') => {
    const host = getApiHost();
    const uaId = window.localStorage.getItem('fe_uaId');
    const uaSessionId = window.sessionStorage.getItem('fe_uaSessionId');
    const wsupUaId = window.localStorage.getItem('wsup_uaId');
    const wsupUaSessionId = window.sessionStorage.getItem('wsup_uaSessionId');
    const feOsName = getFeDeviceDetails().feOsName;
    const feDeviceType = getFeDeviceDetails().feDeviceType;
    const utmCampaign = sessionStorage.getItem('utmCampaign');
    const utmSource = sessionStorage.getItem('utmSource');
    const utmMedium = sessionStorage.getItem('utmMedium');
    const url = type === 'stats' ? `${host}/ai/api/stats/v1/reportEvent` : `${host}/ai/api/stats/v1/reportImpressionEvent`;
    const auth = getAuth();
    const { currentUser } = auth;
    const accessToken = await currentUser?.getIdToken();
    try {
        await fetch(url, {
            method: 'POST',
            headers: {
                client: 'nowgg',
                'Authorization': `WsupV1 ${accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...payload, uaId, wsupUaId, wsupUaSessionId, uaSessionId, feOsName, feDeviceType, client: 'nowgg', utmCampaign, utmSource, utmMedium })
        })
    }
    catch (e) {

    }
};

export const analyzeImage = async (payload: IAnalyzePayload, id: string) => {
    const host = getApiHost();
    const uaId = window.localStorage.getItem('fe_uaId');
    const uaSessionId = window.sessionStorage.getItem('fe_uaSessionId');
    const wsupUaId = window.localStorage.getItem('wsup_uaId');
    const wsupUaSessionId = window.sessionStorage.getItem('wsup_uaSessionId');
    const feOsName = getFeDeviceDetails().feOsName;
    const feDeviceType = getFeDeviceDetails().feDeviceType;
    const utmCampaign = sessionStorage.getItem('utmCampaign');
    const utmSource = sessionStorage.getItem('utmSource');
    const utmMedium = sessionStorage.getItem('utmMedium');
    const url = `${host}/ai/api/char/v1/analyze`;
    const auth = getAuth();
    const { currentUser } = auth;
    const accessToken = await currentUser?.getIdToken();
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                client: 'nowgg',
                'Authorization': `WsupV1 ${accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...payload, uaId, wsupUaId, wsupUaSessionId, uaSessionId, feOsName, feDeviceType, utmCampaign, utmSource, utmMedium })
        });
        const res = await response.json();
        return res;
    } catch (e) {
        if ((e as IError).status !== 'FailureUnauthorized') {
            reportBugsnagError(new Error(`Analyze API Fail: ${e}`) as NotifiableError, id);
        }
        return 'Fail';
    }
}
