import { configureStore } from '@reduxjs/toolkit'
import { userReducer } from './features/userSlice'
import { chatReducer } from './features/chatSlice'
import { deviceReducer } from './features/deviceSlice';
import { feedReducer } from './features/feedSlice';
import { charReducer } from './features/charSlice';
import { notifReducer } from './features/notifSlice';

export const makeStore = () => {
    return configureStore({
        reducer: { user: userReducer, chat: chatReducer, device: deviceReducer, feedSlice: feedReducer, charSlice: charReducer, notifSlice: notifReducer },
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({ serializableCheck: false }),
    })
};

export const store = makeStore();

// Infer the type of makeStore
export type AppStore = ReturnType<typeof makeStore>
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>
export type AppDispatch = AppStore['dispatch']