'use client';

import Script from 'next/script';

const GoogleAnalytics = () => {
  return (
    <>
      <Script
        id="google-analytics-init"
        strategy="afterInteractive"
        src="https://www.googletagmanager.com/gtag/js?id=G-MLYFLY6W2J"
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-MLYFLY6W2J');
                `,
        }}
      />
    </>
  );
};

export default GoogleAnalytics;
